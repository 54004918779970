import React from 'react';
import { useAppContext } from '../core/AppProvider';
//import { useAppWidgetContext } from '../core/AppWidgetProvider';
import { ProgressBar, Table } from "react-bootstrap";

export default function PassengerList(props){

	const { user } = useAppContext();

	const standardColumns = [
		//unencrypted fields
		{name:"reservationID", type:"text", title: "Booking ID", group: "reservation", sticky: true, left: "0px", width: "120px", before_extras: true },
		{name:"staff", type:"text", title: "Staff", group: "reservation", sticky: true, left: "120px", width: "110px", before_extras: true },
		{name:"reservationItemStartDate", type:"text", title: "Tour Start", group: "reservation", before_extras: true, format: "date"     , sticky: true, left: "230px", width: "120px",     },
		//{name:"startDate", type:"text", title: "Res. Start", group: "reservation", before_extras: true, format: "date"},
		//{name:"endDate", type:"text", title: "Res. End", group: "reservation", before_extras: true, format: "date"},
		{name:"productName", type:"text", title: "Tour", group: "reservation", before_extras: true , sticky: true, left: "330px", width: "300px",     },
		{name:"tourCode", type:"text", title: "Code", group: "reservation", before_extras: true , sticky: true, left: "630px", width: "80px",    },
		
		//decrypted fields
		{name:"NameFirst", type:"text", title: "First", group: "passenger", before_extras: true      , sticky: true, left: "710px", width: "120px", },
		{name:"NameMiddle", type:"text", title: "Middle", group: "passenger", before_extras: true        , sticky: true, left: "830px", width: "120px", },
		{name:"NameLast", type:"text", title: "Last", group: "passenger", before_extras: true       , sticky: true, left: "950px", width: "120px", },
		//{name:"Email", type:"text", title: "Email", group: "passenger", before_extras: true }, // removed from spec
		{name:"Gender", type:"text", title: "Gender", group: "passenger", before_extras: true , left: "1070px" },
		
		{name:"ArrivalFlightDate", type:"text", title: "Arrival Flight Date", group: "arrival", before_extras: true, format: "date"},
		{name:"ArrivalFlightTime", type:"text", title: "Arrival Flight Time", group: "arrival", before_extras: true },
		{name:"ArrivalFlightNumber", type:"text", title: "Arrival Flight Number", group: "arrival", before_extras: true },
		{name:"roomType", type:"text", title: "Room Type", group: "reservation", before_extras: true },
		{name:"roomID", type:"text", title: "Room ID", group: "reservation", left: "120px", width: "110px", before_extras: true },
		{name:"DepartureFlightDate", type:"text", title: "Departure Flight Date", group: "departure", before_extras: true, format: "date" },
		{name:"DepartureFlightTime", type:"text", title: "Departure Flight Time", group: "departure", before_extras: true },
		{name:"DepartureFlightNumber", type:"text", title: "Departure Flight Number", group: "departure", before_extras: true },
		
		{name:"Mobile", type:"text", title: "Mobile", group: "passenger", before_extras: true },
		
		{name:"passengerID", type:"text", title: "Passenger ID", group: "passenger", before_extras: true },

		
		// pre post addons go here now
		{name:"ssup", type:"text", title: "SSUP", group: "reservation", left: "120px", width: "110px", before_extras: false },

		{name:"CountryOfCitizenship", type:"text", title: "Passport Country", group: "passport", before_extras: false },
		{name:"PassportNumber", type:"text", title: "Passport Number", group: "passport", before_extras: false},
		{name:"PassportIssueDate", type:"text", title: "Passport Issue Date", group: "passport", before_extras: false, format: "date"},
		{name:"PassportExpiryDate", type:"text", title: "Passport Expiry Date", group: "passport", before_extras: false, format: "date"},
		
		{name:"DOB", type:"text", title: "DOB", group: "passenger", before_extras: false, format: "date"},
		{name:"DietaryRequirements", type:"text", title: "Dietary Requirements", group: "passenger", before_extras: false },
		{name:"notes", type:"text", title: "Notes", group: "notes", before_extras: false},
		
		{name:"TravelInsuranceProvider", type:"text", title: "Insurance Provider", group: "emergency", before_extras: false},
		{name:"TravelInsurancePolicyNumber", type:"text", title: "Insurance Policy Number", group: "emergency", before_extras: false},
		{name:"TravelInsuranceContactNumber", type:"text", title: "Insurance Contact Number", group: "emergency", before_extras: false},

		{name:"EmergencyContactName", type:"text", title: "Emergency Contact Name", group: "emergency", before_extras: false},
		{name:"EmergencyContactNumber", type:"text", title: "Emergency Contact Number", group: "emergency", before_extras: false},
		{name:"reservationBookingConfirmedDate", type:"text", title: "Booking Confirmed Date", group: "reservation", before_extras: false},
	];

	if (user.otgUser) {
		standardColumns.unshift(
			{name:"supplierName", type:"text", title: "Supplier", group: "supplier"},
		);
	}

	const showGroup = (groupName) => {
		if (!user.selectedColumnGroups) { return true; } // default to on if structure DNE
		if (typeof user.selectedColumnGroups[groupName] === "undefined") { return true; } // default to on if not defined
		return user.selectedColumnGroups[groupName]; // if set, return actual state
	}
	
	//const collatable_columns = ["Pre Tour", "Post Tour"];
	
	const getExtraDetails = (passenger, extraName) => {
		let results = []; // we now collect results incase a row has more than one of the same extra due to collpasing
		if (passenger.extras) {
			for(let i=0; i < passenger.extras.length; i++) {
				if (passenger.extras[i].productName === extraName) {
					if (passenger.extras[i].reservationItemNights > 0) {
						if (passenger.extras[i].category !== "accommodation" && passenger.extras[i].category !== "prepost" ) {						
							results.push('1');
						} else {
							results.push(passenger.extras[i].reservationItemNights + " nights");
						}
					} else {
						results.push('1');
					}
				}
			}
		}
		return (<span dangerouslySetInnerHTML={{ __html: results.join(", ") }}/>);
	}

	// eslint-disable-next-line
	//useEffect(() => { loadData() }, [props]);

	/*const simplifyExtraNames = (extraNames) => {
		
		let used_collatable_columns = [];
		let new_columns = [];
		for(let i=0; i < extraNames.length; i++) {
			let collatable = false;
			for (let j=0; j < collatable_columns.length; j++) {
				if (extraNames[i].startsWith(collatable_columns[j])) {
					collatable = true;
				}
			}
			if (collatable) {
				if (used_collatable_columns.indexOf(extraNames[i].split(" ")[0]) === -1) {
					used_collatable_columns.push(extraNames[i].split(" ")[0]);
					new_columns.push(extraNames[i].split(" ")[0]);
				}
			} else {
				new_columns.push(extraNames[i]);
			}
		}
		return new_columns;
	}*/
	
	const data_format = (data, format) => {
		//console.log(format);
		if (format === "date") {
			let bits = data.split("-");
			//make month human readable
			bits[1] = parseInt(bits[1]);
			let months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
			bits[1] = months[bits[1]-1];
			if (bits.join("/").trim() === "/") {return "-";}
			return bits.reverse().join("/");
		}
		return data;
	}

	return (
		<>
			
			{(props.loading) ? (
				<>
					<ProgressBar className="mb-2"/>
					<ProgressBar className="mb-2"/>
					<ProgressBar className="mb-2"/>
				</>
			) : (
				<>
					{(!props.loaded) ? (
						<div></div>
					) : (
						<>
							{((!props.data)||(!props.data.data)||(!props.data.meta)||(!props.data.meta.extraNames)) ? (
								<div>Error fetching data from remote server.</div>
							) : (
								<>
									{(props.data.data.length === 0) ? (
										<div>No results</div>
									) : (
										<Table striped bordered hover>
											<thead>
												<tr>
													{standardColumns.filter(col=>showGroup(col.group)).filter(col=>col.before_extras===true).map((col) => (
														<>
															{(col.sticky && col.sticky === true) ? (
																<th style={{ zIndex: "102", border: "1px solid black", left: col.left, width: col.width, minWidth: col.width, top: "0px", position: "sticky"}} class="text-nowrap" scope="col">{col.title}</th>
															) : (
																<th style={{ zIndex: "101", border: "1px solid black", top: "0px", position: "sticky"}} class="text-nowrap" scope="col">{col.title}</th>
															)}
														</>
													))}

													{showGroup("extras") && [...new Set(props.data.meta.extraNames)].map((extraName) => (
														<th style={{ zIndex: "101", border: "1px solid black", top: "0px", position: "sticky"}} class="text-nowrap" scope="col">{extraName}</th>
													))}
													
													{standardColumns.filter(col=>showGroup(col.group)).filter(col=>col.before_extras===false).map((col) => (
														<>
															{(col.sticky && col.sticky === true) ? (
																<th style={{ zIndex: "102", border: "1px solid black", left: col.left, width: col.width, minWidth: col.width, top: "0px", position: "sticky"}} class="text-nowrap" scope="col">{col.title}</th>
															) : (
																<th style={{ zIndex: "101", border: "1px solid black", top: "0px", position: "sticky", left: col.left?col.left:undefined}} class="text-nowrap" scope="col">{col.title}</th>
															)}
														</>
													))}
												</tr>
											</thead>
											<tbody>
												{props.data.data.map((passenger, index) => (
													<tr key={passenger.passengerID+"_"+index} onClick={()=>{}} style={{position: "relative"}}>
														{standardColumns.filter(col=>showGroup(col.group)).filter(col=>col.before_extras===true).map((col, col_index) => (
															<>
																{(col.sticky && col.sticky === true) ? (
																	<td style={{zIndex:"100", position: "sticky", left: col.left, width: col.width, minWidth: col.width,}} class="text-nowrap">{data_format(passenger[col.name], col.format)} </td>
																) : (
																	<td class="text-nowrap">{data_format(passenger[col.name], col.format)}</td>
																)}
															</>
														))}

														{showGroup("extras") && [...new Set(props.data.meta.extraNames)].map((extraName, index) => (
															<td class="text-nowrap">{getExtraDetails(passenger, extraName)}</td>
														))}

														{standardColumns.filter(col=>showGroup(col.group)).filter(col=>col.before_extras===false).map((col, col_index) => (
															<>
																{(col.sticky && col.sticky === true) ? (
																	<td style={{zIndex:"100", position: "sticky", left: col.left, width: col.width, minWidth: col.width,}} class="text-nowrap">{data_format(passenger[col.name], col.format)}</td>
																) : (
																	<td style={{ left: col.left?col.left:undefined, }} class="text-nowrap">{data_format(passenger[col.name], col.format)}</td>
																)}
															</>
														))}
													</tr>
												))}
											</tbody>
										</Table>
									)}
								</>
							)}
						</>
					)}
				</>
			)}
		</>
	);
}